import { FC, useCallback } from 'react';
import { Form, Formik } from 'formik';

import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import Dialog, { DialogProps } from 'components/Dialog';
import DialogButton from 'components/DialogButton';
import AttendantFormFields, {
  FormFields,
  schema,
} from 'components/AttendantFormFields';

import { useCreateAttendant } from 'features/Attendant';

type Props = Omit<DialogProps, 'title' | 'children'>;

const initial: FormFields = {
  firstName: '',
  lastName: '',
  dateOfBirth: null,
};

const AddAttendantDialog: FC<Props> = props => {
  const create = useCreateAttendant();

  const handleSubmit = useCallback(
    ({ dateOfBirth, ...values }: FormFields) => {
      const form = {
        ...values,
        dateOfBirth: dateOfBirth!,
      };

      create.mutate(form, {
        onSuccess: props.onClose,
      });
    },
    [create, props.onClose]
  );

  return (
    <Dialog variant="back" title="Pridėti dalyvį" {...props}>
      <Formik<FormFields>
        initialValues={initial}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        <Form>
          <Box sx={{ minHeight: 'calc(100vh - 128px)', p: 2 }}>
            <AttendantFormFields />
          </Box>

          <DialogButton
            type="submit"
            loading={create.isPending}
            endIcon={<PersonIcon />}>
            Pridėti
          </DialogButton>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default AddAttendantDialog;
