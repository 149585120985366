import Bullet from 'components/Bullet';

import { formatAgo } from 'lib';
import { MessageListView } from 'schema';

type Props = {
  message: MessageListView;
};

const MessageDetails = ({ message }: Props) => (
  <>
    {`${message.author.firstName} ${message.author.lastName}`} <Bullet />{' '}
    {formatAgo(message.sent)}
  </>
);

export default MessageDetails;
