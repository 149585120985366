import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';

type Context = {
  pop: (text: string) => void;
};

const NotificationsContext = createContext<Context>({
  pop: () => {},
});

export const useNotification = () => useContext(NotificationsContext);

const origin: SnackbarOrigin = {
  horizontal: 'center',
  vertical: 'bottom',
};

type Props = {
  children?: ReactNode;
};

type Message = {
  text: string;
};

function Notifications({ children }: Props) {
  const [message, setMessage] = useState<Message | null>(null);

  const pop = useCallback((text: string) => {
    setMessage({ text });
  }, []);

  const handleClose = useCallback(() => {
    setMessage(null);
  }, []);

  const value = useMemo(() => ({ pop }), [pop]);

  return (
    <NotificationsContext.Provider value={value}>
      {children}
      <Snackbar
        anchorOrigin={origin}
        open={!!message}
        autoHideDuration={6000}
        message={message?.text}
        onClose={handleClose}
      />
    </NotificationsContext.Provider>
  );
}

export default Notifications;
