import { FC } from 'react';

import AvatarSkeleton from 'components/AvatarSkeleton';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import DetailsSection from 'components/DetailsSection';
import NoImage from 'components/NoImage';

const SessionDetailsSkeleton: FC = () => (
  <Box>
    <Paper square>
      <NoImage />
    </Paper>

    <DetailsSection sx={{ display: 'flex', alignItems: 'center' }}>
      <Box>
        <AvatarSkeleton />
      </Box>

      <Box sx={{ ml: 2, width: '100%' }}>
        <Typography variant="h6">
          <Skeleton sx={{ width: '100%' }} />
        </Typography>

        <Typography color="text.secondary">
          <Skeleton sx={{ width: '75%' }} />
        </Typography>

        <Typography color="text.secondary">
          <Skeleton sx={{ width: '80%' }} />
        </Typography>
      </Box>
    </DetailsSection>

    <DetailsSection>
      <Typography sx={{ fontWeight: 'bold' }}>
        <Skeleton />
      </Typography>

      <Typography>
        <Skeleton sx={{ width: '76%' }} />
      </Typography>

      <Typography>
        <Skeleton sx={{ width: '88%' }} />
      </Typography>

      <Typography>
        <Skeleton sx={{ width: '50%' }} />
      </Typography>
    </DetailsSection>
  </Box>
);

export default SessionDetailsSkeleton;
