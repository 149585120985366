import { FC, useState, MouseEvent, useCallback } from 'react';
import { format } from 'date-fns/format';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import ImageIcon from '@mui/icons-material/Image';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SessionDetailsDialog from 'components/SessionDetailsDialog';

import { DATE_TIME_FORMAT } from 'const';
import { useDialog } from 'components/Dialog';
import { romanWeek } from 'lib';

import { useDeleteInvitation } from 'features/Invitation';
import { AttendantInvitationListView } from 'schema';

type Props = {
  invitation: AttendantInvitationListView;
  last: boolean;
};

const SessionInvitation: FC<Props> = ({ invitation, last }) => {
  const { openDialog, onClose, ...dialog } = useDialog();
  const { id, session } = invitation;
  const { weekSchedule, shift, activity } = session;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const deleteInvitation = useDeleteInvitation();

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOpenDetailsClick = useCallback(() => {
    openDialog();
    handleClose();
  }, [handleClose, openDialog]);

  const handleDeleteInvitation = useCallback(() => {
    deleteInvitation.mutate({ invitation: id });
  }, [deleteInvitation, id]);

  const secondary =
    activity.type === 'regular'
      ? weekSchedule
          ?.map(item => `${romanWeek(item.weekDay)} ${item.startTime}`)
          .join(', ')
      : format(shift.start, DATE_TIME_FORMAT);

  return (
    <>
      <ListItem divider={!last} onClick={handleOpenDetailsClick}>
        <ListItemIcon>
          <Avatar
            variant="square"
            sx={{
              '& img': {
                width: '100%',
                height: 40,
                objectFit: 'cover',
              },
            }}>
            {activity.photo ? (
              <img src={activity.photo.publicUrl} alt={activity.name} />
            ) : (
              <ImageIcon />
            )}
          </Avatar>
        </ListItemIcon>

        <ListItemText primary={activity.name} secondary={secondary} />

        <ListItemSecondaryAction>
          <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleOpenDetailsClick}>Peržiūrėti</MenuItem>
        <MenuItem onClick={handleDeleteInvitation}>Ištrinti</MenuItem>
      </Menu>

      <SessionDetailsDialog
        {...dialog}
        onClose={onClose}
        invitation={invitation}
        session={session}
      />
    </>
  );
};

export default SessionInvitation;
