import { FC } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import UserAvatar from 'components/UserAvatar';
import { AttendantListView } from 'schema';

type Props = BoxProps & {
  attendants: AttendantListView[];
};

const AttendantAvatarList: FC<Props> = ({ attendants, sx = [], ...rest }) => (
  <Box sx={[{ display: 'flex' }, ...(Array.isArray(sx) ? sx : [sx])]} {...rest}>
    {attendants.map(attendant => (
      <UserAvatar user={attendant} key={attendant.id} sx={{ mr: 1 }} />
    ))}
  </Box>
);

export default AttendantAvatarList;
