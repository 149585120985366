import { FC } from 'react';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import CssBaseline from '@mui/material/CssBaseline';
import Theme from 'components/Theme';
import Notifications from 'components/Notifications';

import { router } from 'routes';
import { locale } from 'const';

const client = new QueryClient();

const App: FC = () => (
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
    <QueryClientProvider client={client}>
      <ReactQueryDevtools initialIsOpen={false} />

      <Theme>
        <Notifications>
          <CssBaseline />
          <RouterProvider router={router} />
        </Notifications>
      </Theme>
    </QueryClientProvider>
  </LocalizationProvider>
);

export default App;
