import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import { MediaView } from 'schema';

type Props = {
  images: MediaView[];
};

type ImageData = {
  img: string;
  title: string;
  rows?: number;
  cols?: number;
};

const Gallery = ({ images }: Props) => {
  const itemData = images.map(({ publicUrl }, i) => {
    const image: ImageData = {
      img: publicUrl!,
      title: `Nuotrauka ${i}`,
    };

    if (i === 0) {
      image.rows = 2;
      image.cols = 2;
    }

    return image;
  });

  return (
    <ImageList
      sx={{ width: '100%' }}
      variant="quilted"
      cols={2}
      rowHeight={121}>
      {itemData.map(item => (
        <ImageListItem
          key={item.img}
          cols={item.cols || 1}
          rows={item.rows || 1}>
          <img src={item.img} alt={item.title} loading="lazy" />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default Gallery;
