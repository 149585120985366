import { FC } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import UserAvatar from 'components/UserAvatar';
import ConfirmableIconButton from 'components/ConfirmableIconButton';
import ListItemDialogButton from 'components/ListItemDialogButton';
import AttendandEditDialog from 'components/AttendandEditDialog';

import { AttendantListView } from 'schema';
import { useDeleteAttendant } from 'features/Attendant';

type Props = {
  attendant: AttendantListView;
};

const AttendantListItem: FC<Props> = ({ attendant }) => {
  const { id, fullName } = attendant;
  const destroy = useDeleteAttendant(id);

  return (
    <ListItem disablePadding>
      <ListItemDialogButton
        dialog={props => (
          <AttendandEditDialog attendant={attendant} {...props} />
        )}>
        <ListItemIcon>
          <UserAvatar user={attendant} />
        </ListItemIcon>

        <ListItemText>{fullName}</ListItemText>

        <ListItemSecondaryAction>
          <ConfirmableIconButton
            text={`Ar tikrai norite ištrinti ${fullName}?`}
            onConfirm={destroy.mutate}>
            <DeleteIcon />
          </ConfirmableIconButton>
        </ListItemSecondaryAction>
      </ListItemDialogButton>
    </ListItem>
  );
};

export default AttendantListItem;
