import { FC } from 'react';

import Card, { CardProps } from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import UserAvatar from 'components/UserAvatar';
import MessageDialog from 'components/MessageDialog';
import MessageDetails from 'components/MessageDetails';

import { useDialog } from 'components/Dialog';
import { MessageListView } from 'schema';

type Props = CardProps & {
  message: MessageListView;
};

const MessageCard: FC<Props> = ({ message, ...rest }) => {
  const { openDialog, ...dialog } = useDialog();

  return (
    <>
      <Card onClick={openDialog} {...rest}>
        <CardHeader
          avatar={<UserAvatar user={message.author} />}
          title={message.title}
          subheader={<MessageDetails message={message} />}
        />

        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {message.content}
          </Typography>
        </CardContent>
      </Card>

      <MessageDialog messageId={message.id} {...dialog} />
    </>
  );
};

export default MessageCard;
