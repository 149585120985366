import Box from '@mui/material/Box';
import SessionInvitations from 'components/SessionInvitations';
import MySessions from 'components/MySessions';
// import SessionSearch from 'components/SessionSearch';

const Sessions = () => (
  <Box sx={{ pb: 7 }}>
    <SessionInvitations />
    <MySessions />
    {/* <SessionSearch /> */}
  </Box>
);

export default Sessions;
