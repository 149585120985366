import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import AttendantFormFields, {
  FormFields,
  schema,
} from 'components/AttendantFormFields';
import DialogButton from 'components/DialogButton';
import { useUpdateAttendant } from 'features/Attendant';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import { AttendantDetailsView } from 'schema';

type Props = {
  attendant: AttendantDetailsView;
};

const AttendandEditDialogForm = ({ attendant }: Props) => {
  const update = useUpdateAttendant(attendant.id);

  const handleSubmit = useCallback(
    ({ dateOfBirth, ...form }: FormFields) => {
      update.mutate({
        ...form,
        dateOfBirth: dateOfBirth!,
      });
    },
    [update]
  );

  return (
    <Formik<FormFields>
      initialValues={attendant}
      onSubmit={handleSubmit}
      validationSchema={schema}>
      <Form>
        <Box sx={{ minHeight: 'calc(100vh - 128px)', p: 2 }}>
          <AttendantFormFields />
        </Box>

        <DialogButton
          type="submit"
          loading={update.isPending}
          endIcon={<SaveIcon />}>
          Saugoti
        </DialogButton>
      </Form>
    </Formik>
  );
};

export default AttendandEditDialogForm;
