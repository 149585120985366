import { FC } from 'react';
import { format } from 'date-fns/format';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

import { DATE_FORMAT, DATE_TIME_FORMAT } from 'const';
import { weekDayName } from 'lib';
import { SessionDetailsView } from 'schema';

type Props = {
  session: SessionDetailsView;
};

const SessionDetailsSchedule: FC<Props> = ({ session }) => {
  const { shift, weekSchedule, activity } = session;
  const { start, end } = shift;

  return (
    <Box>
      {activity.type === 'regular' ? (
        <Box>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ fontWeight: 'bold' }}>Tvarkaraštis</Typography>

            <Typography color="text.secondary">
              Nuo {format(start, DATE_FORMAT)}{' '}
              {end && `iki ${format(end, DATE_FORMAT)}`}
            </Typography>
          </Box>

          {weekSchedule && (
            <List>
              {weekSchedule.map(({ weekDay, startTime }, i) => (
                <ListItem key={i} divider={i < weekSchedule.length - 1}>
                  <ListItemText>{weekDayName(weekDay)}</ListItemText>
                  <ListItemSecondaryAction>{startTime}</ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      ) : (
        <Box sx={{ p: 2 }}>
          <Typography sx={{ fontWeight: 'bold' }}>Data</Typography>

          {end ? (
            <>
              <Typography color="text.secondary">
                Nuo {format(start, DATE_TIME_FORMAT)}
              </Typography>

              <Typography color="text.secondary">
                Iki {format(end, DATE_TIME_FORMAT)}
              </Typography>
            </>
          ) : (
            <Typography color="text.secondary">
              {format(start, DATE_TIME_FORMAT)}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SessionDetailsSchedule;
