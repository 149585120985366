import { SyntheticEvent, useCallback, useState } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Dialog, { DialogProps } from 'components/Dialog';
import TabPanel from 'components/TabPanel';
import SessionDetails from 'components/SessionDetails';
import SessionResults from 'components/SessionResults';

type Props = Omit<DialogProps, 'children' | 'title'> & {
  session: {
    id: number;
    activity: {
      name: string;
    };
  };
};

const UserSessionDetailsDialog = ({ session, ...rest }: Props) => {
  const [value, setValue] = useState(0);

  const handleChange = useCallback(
    (event: SyntheticEvent, newValue: number) => {
      setValue(newValue);
    },
    []
  );

  return (
    <Dialog {...rest} title={session.activity.name}>
      <Tabs value={value} onChange={handleChange} variant="fullWidth">
        <Tab label="Būrelis" />
        <Tab label="Rezultatai" />
      </Tabs>

      <TabPanel value={value} index={0}>
        <SessionDetails id={session.id} />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <SessionResults id={session.id} />
      </TabPanel>
    </Dialog>
  );
};

export default UserSessionDetailsDialog;
