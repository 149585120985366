import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Alert, { AlertColor } from '@mui/material/Alert';

import { useCancelPayment, useConfirmPayment } from 'features/Invoice';

const PaymentStatus = () => {
  const [params] = useSearchParams();
  const [cancelled, setCancelled] = useState(false);
  const [pending, setPending] = useState(false);
  const [received, setReceived] = useState(false);

  const { mutate: cancel } = useCancelPayment();
  const { mutate: confirm } = useConfirmPayment();

  useEffect(() => {
    const token = params.get('token');
    const canceled = params.get('canceled');
    const pending = params.get('pending');
    const data = params.get('data');

    if (canceled && data) {
      cancel(
        { token: data },
        {
          onSuccess: () => {
            setCancelled(true);
          },
        }
      );
    } else if (pending) {
      setPending(true);
    } else if (token) {
      confirm(
        { token },
        {
          onSuccess: () => {
            setReceived(true);
          },
        }
      );
    }
  }, [cancel, confirm, params]);

  let severity: AlertColor = 'success';
  let message: string = 'Jūsų mokėjimas gautas';

  if (cancelled) {
    severity = 'warning';
    message = 'Mokėjimas atšauktas, bandykite dar kartą';
  } else if (pending) {
    severity = 'info';
    message = 'Mokėjimas priimtas, bet laukiame patvirtinimo iš banko';
  }

  return cancelled || pending || received ? (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Alert variant="filled" severity={severity}>
        {message}
      </Alert>
    </Box>
  ) : null;
};

export default PaymentStatus;
