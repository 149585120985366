import { FC } from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import AvatarSkeleton from 'components/AvatarSkeleton';

type Props = {};

const MessagesSkeleton: FC<Props> = () => (
  <Card sx={{ m: 1 }}>
    <CardHeader
      avatar={<AvatarSkeleton />}
      title={<Skeleton sx={{ width: '75%' }} />}
      subheader={<Skeleton sx={{ width: '50%' }} />}
    />

    <CardContent>
      <Skeleton sx={{ width: '50%' }} />
      <Skeleton sx={{ width: '88%' }} />
      <Skeleton sx={{ width: '66%' }} />
    </CardContent>
  </Card>
);

export default MessagesSkeleton;
