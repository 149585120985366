import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SessionResultsResult from 'components/SessionResultsResult';

import { GroupedSessionResults } from 'schema';

type Props = {
  group: GroupedSessionResults;
};

const SessionResultsAttendant = ({ group }: Props) => {
  const { attendant, results } = group;
  const { firstName, lastName } = attendant;

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
        {firstName} {lastName}
      </Typography>

      {results.map(result => (
        <SessionResultsResult key={result.id} result={result} />
      ))}
    </Box>
  );
};

export default SessionResultsAttendant;
