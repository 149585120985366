import { FC, ReactNode } from 'react';

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

type Props = {
  children?: ReactNode;
};

const InvoiceTable: FC<Props> = ({ children }) => (
  <TableContainer sx={{ bgcolor: '#ffffff', minHeight: '100vh' }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell component="th">Mėnuo</TableCell>
          <TableCell component="th">Suma</TableCell>
          <TableCell component="th" />
        </TableRow>
      </TableHead>

      <TableBody>{children}</TableBody>
    </Table>
  </TableContainer>
);

export default InvoiceTable;
