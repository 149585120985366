import { FC } from 'react';
import { isToday } from 'date-fns/isToday';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Blank from 'components/Blank';
import TimetableScheduleTimeSlot from 'components/TimetableScheduleTimeSlot';

import { useDaySchedule } from 'features/Schedule';
import TimetableScheduleTimeSlotSkeleton from 'components/TimetableScheduleTimeSlotSkeleton';

type Props = {
  day: Date;
};

const TimetableSchedule: FC<Props> = ({ day }) => {
  const scheduleQuery = useDaySchedule(day);

  if (!scheduleQuery.data)
    return (
      <Box sx={{ p: 1, pt: '118px' }}>
        <TimetableScheduleTimeSlotSkeleton />
      </Box>
    );

  const schedule = scheduleQuery.data;

  if (schedule.publicHoliday) {
    return (
      <Blank>
        <CalendarMonthIcon fontSize="inherit" color="success" />
        <Typography color="success.main">Nedarbo diena</Typography>
      </Blank>
    );
  }

  if (schedule.slots.length > 0) {
    return (
      <Box sx={{ p: 1, pt: '118px' }}>
        {schedule.slots.map((item, i) => (
          <TimetableScheduleTimeSlot key={i} timeSlot={item} sx={{ mb: 1 }} />
        ))}
      </Box>
    );
  }

  return (
    <Blank>
      <CalendarMonthIcon fontSize="inherit" />

      <Typography>
        {isToday(day) ? 'Šiandien' : 'Šią dieną'} užsiėmimų nėra
      </Typography>
    </Blank>
  );
};

export default TimetableSchedule;
