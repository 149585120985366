import { FC, ReactNode } from 'react';

import ListItemButton, {
  ListItemButtonProps,
} from '@mui/material/ListItemButton';

import { useDialog } from 'components/Dialog';

type DialogProps = Omit<ReturnType<typeof useDialog>, 'openDialog'>;

type Props = ListItemButtonProps & {
  dialog: (props: DialogProps) => ReactNode;
};

const ListItemDialogButton: FC<Props> = ({ dialog, ...rest }) => {
  const { openDialog, ...dialogProps } = useDialog();

  return (
    <>
      <ListItemButton onClick={openDialog} {...rest} />
      {dialog(dialogProps)}
    </>
  );
};

export default ListItemDialogButton;
