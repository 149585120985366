import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import logo from 'assets/img/logo.png';

type Props = BoxProps & {
  title: string;
};

const AuthLogo = ({ title, sx = [], ...props }: Props) => (
  <Box
    sx={[
      { pb: 2, '& img': { width: '50%' } },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}>
    <img src={logo} alt="iGudo" />

    <Typography variant="h6" color="text.secondary">
      {title}
    </Typography>
  </Box>
);

export default AuthLogo;
