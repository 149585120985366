import { FC, ReactNode, useCallback } from 'react';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ConfirmDialog, { ConfirmDialogProps } from 'components/ConfirmDialog';

import { useDialog } from 'components/Dialog';

type Props = Omit<ConfirmDialogProps, 'open'> &
  IconButtonProps & {
    children?: ReactNode;
    onConfirm?: () => void;
  };

const ConfirmableIconButton: FC<Props> = props => {
  const { children, onConfirm, title, text, ...rest } = props;
  const { openDialog, onClose, ...dialog } = useDialog();

  const handleConfirm = useCallback(() => {
    onClose();
    onConfirm && onConfirm();
  }, [onClose, onConfirm]);

  return (
    <>
      <IconButton onClick={openDialog} {...rest}>
        {children}
      </IconButton>
      <ConfirmDialog
        {...dialog}
        title={title}
        text={text}
        onClose={onClose}
        onCancel={onClose}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default ConfirmableIconButton;
