import { FC } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useField } from 'formik';

type Props = TextFieldProps & {
  name: string;
};

const FormTextField: FC<Props> = ({ name, ...rest }) => {
  const [field, { error, touched }] = useField(name);
  const hasError = Boolean(error && touched);

  return (
    <TextField
      variant="standard"
      error={hasError}
      helperText={hasError && error}
      {...field}
      {...rest}
    />
  );
};

export default FormTextField;
