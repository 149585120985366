import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { sessionKeys } from 'features/Session';
import { useErrorHandler } from 'lib';
import { useNotification } from 'components/Notifications';

import {
  AttendantInvitationListView,
  invitationAccept,
  invitationDelete,
  invitationIndex,
} from 'schema';

export const invitationKeys = {
  all: [{ scope: 'invitation' }] as const,

  lists: () => [{ ...invitationKeys.all[0], entity: 'list' }] as const,
  list: () => [{ ...invitationKeys.lists()[0] }] as const,
};

export const useInvitations = () =>
  useQuery({ queryKey: invitationKeys.list(), queryFn: invitationIndex });

export const useAcceptance = () => {
  const queryClient = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: invitationAccept,
    onSuccess: ({ removed }) => {
      if (removed) {
        pop('Registracija sėkminga');

        queryClient.invalidateQueries({ queryKey: invitationKeys.list() });
        queryClient.invalidateQueries({
          queryKey: sessionKeys.mySessionList(),
        });
      }
    },
  });
};

export const useDeleteInvitation = () => {
  const queryClient = useQueryClient();
  const queryKey = invitationKeys.list();
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: invitationDelete,
    onMutate: async ({ invitation }) => {
      await queryClient.cancelQueries({ queryKey });

      const snapshot =
        queryClient.getQueryData<AttendantInvitationListView[]>(queryKey);

      queryClient.setQueryData<AttendantInvitationListView[]>(
        queryKey,
        attendants => attendants?.filter(item => item.id !== invitation) ?? []
      );

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      queryClient.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
