import { ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';

import AuthChangePassword from 'components/AuthChangePassword';

type Props = {
  children: ReactElement;
};

const AuthNewPassword = ({ children }: Props) => {
  const [params] = useSearchParams();
  const token = params.get('recoveryToken');

  if (token) {
    return <AuthChangePassword token={token} />;
  }

  return children;
};

export default AuthNewPassword;
