import { FC } from 'react';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Skeleton from '@mui/material/Skeleton';

type Props = {};

const InvoiceRowSkeleton: FC<Props> = () => {
  return (
    <TableRow>
      <TableCell>
        <Skeleton />
      </TableCell>

      <TableCell colSpan={2}>
        <Skeleton />
      </TableCell>
    </TableRow>
  );
};

export default InvoiceRowSkeleton;
