import { FC, useCallback, useState } from 'react';
import { format } from 'date-fns/format';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import InvoiceRowDetails from 'components/InvoiceRowDetails';
import InvoiceRowPaymentButton from 'components/InvoiceRowPaymentButton';

import { locale } from 'const';
import { ParentInvoiceListView } from 'schema';

type Props = {
  invoice: ParentInvoiceListView;
};

const InvoiceRow: FC<Props> = ({ invoice }) => {
  const [open, setOpen] = useState(
    window.location.hash.replace('#', '') === invoice.invoiceNumber
  );

  const handleClick = useCallback(() => {
    setOpen(v => !v);
  }, []);

  return (
    <>
      <TableRow onClick={handleClick}>
        <TableCell sx={[open && { borderBottom: 0 }]}>
          {format(new Date(invoice.period), "yyyy 'm.' LLLL", { locale })}
        </TableCell>

        <TableCell sx={[open && { borderBottom: 0 }]}>
          {invoice.total.toFixed(2)} €
        </TableCell>

        <TableCell sx={[{ textAlign: 'right' }, open && { borderBottom: 0 }]}>
          <InvoiceRowPaymentButton invoice={invoice} />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <InvoiceRowDetails open={open} invoice={invoice} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default InvoiceRow;
