import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemDialogButton from 'components/ListItemDialogButton';
import AccountSettingsDialog from 'components/AccountSettingsDialog';
import AttendandsDialog from 'components/AttendandsDialog';
import LogoutMenuItem from 'components/LogoutMenuItem';

import { useMe } from 'features/Auth';

const Account = () => {
  const me = useMe();

  if (!me.data) return <>Kraunasi...</>;

  const { firstName, lastName, email } = me.data;

  return (
    <List>
      <ListItem divider>
        <ListItemText primary={`${firstName}, ${lastName}`} secondary={email} />
      </ListItem>

      <ListItem disablePadding>
        <ListItemDialogButton
          dialog={props => <AccountSettingsDialog {...props} />}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>

          <ListItemText>Paskyros nustatymai</ListItemText>
        </ListItemDialogButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemDialogButton dialog={props => <AttendandsDialog {...props} />}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>

          <ListItemText>Dalyviai</ListItemText>
        </ListItemDialogButton>
      </ListItem>

      <ListItem disablePadding>
        <LogoutMenuItem />
      </ListItem>
    </List>
  );
};

export default Account;
