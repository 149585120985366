import { useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import FullScreen from 'components/FullScreen';
import FormTextField from 'components/FormTextField';
import AuthLogo from 'components/AuthLogo';
import Alert from '@mui/material/Alert';

import { useStartRecovery } from 'features/Auth';
import { useAuth } from 'components/AuthGuard';
import { useIsDesktop } from 'lib';

type Fields = {
  email: string;
};

const initial = {
  email: '',
};

const schema = object({
  email: string()
    .required('Prašome įvesti el pašto adresą')
    .email('Neteisingas el. pašto formatas'),
});

const AuthPasswordRecovery = () => {
  const [sentTo, setSentTo] = useState<string>();
  const desktop = useIsDesktop();
  const recovery = useStartRecovery();
  const { login } = useAuth();

  const handleSubmit = useCallback(
    (values: Fields) => {
      recovery.mutate(values, {
        onSuccess: (_, { email }) => {
          setSentTo(email);
        },
      });
    },
    [recovery]
  );

  return (
    <FullScreen sx={{ minHeight: '100vh' }}>
      <Box sx={{ width: desktop ? '500px' : '100%' }}>
        <AuthLogo title="Slaptažodžio keitimas" />

        {sentTo ? (
          <Alert severity="success">
            Slaptažodžio keitimo nuoroda išsiųsta el. paštu {sentTo}, jeigu
            tokia paskyra egzistuoja.
          </Alert>
        ) : (
          <Formik<Fields>
            initialValues={initial}
            onSubmit={handleSubmit}
            validationSchema={schema}>
            <Form>
              <Stack spacing={4}>
                <FormTextField
                  name="email"
                  label="El. paštas"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Link onClick={login}>Prisijungti</Link>
                  </Box>
                </Box>

                <LoadingButton
                  loading={recovery.isPending}
                  variant="contained"
                  type="submit">
                  Tęsti
                </LoadingButton>
              </Stack>
            </Form>
          </Formik>
        )}
      </Box>
    </FullScreen>
  );
};

export default AuthPasswordRecovery;
