import { FC } from 'react';
import { format } from 'date-fns/format';

import Card, { CardProps } from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import AttendantAvatarList from 'components/AttendantAvatarList';
import NoImage from 'components/NoImage';
import UserSessionDetailsDialog from 'components/UserSessionDetailsDialog';

import { DATE_TIME_FORMAT } from 'const';
import { useDialog } from 'components/Dialog';
import { romanWeek } from 'lib';
import { ParentSessionListView } from 'schema';

type Props = CardProps & {
  session: ParentSessionListView;
};

const UserSessionCard: FC<Props> = ({ session, sx = [], ...rest }) => {
  const { openDialog, ...dialog } = useDialog();
  const { activity, attendantRegistrations, coach, weekSchedule, shift } =
    session;

  return (
    <>
      <Card
        elevation={0}
        sx={[
          { border: '1px solid #e0e0e0' },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        onClick={openDialog}
        {...rest}>
        {activity.photo ? (
          <CardMedia
            component="img"
            height={240}
            image={activity.photo.publicUrl}
            alt={activity.name}
          />
        ) : (
          <NoImage />
        )}

        <CardContent>
          <Typography gutterBottom>
            <Link underline="hover">
              {coach.user.firstName} {coach.user.lastName}
            </Link>
          </Typography>

          <Typography variant="h6">{activity.name}</Typography>

          {activity.type === 'regular' ? (
            weekSchedule?.map((item, i) => (
              <Typography color="text.secondary" key={i}>
                {romanWeek(item.weekDay)}: {item.startTime}
              </Typography>
            ))
          ) : (
            <Typography color="text.secondary">
              {format(shift.start, DATE_TIME_FORMAT)}
            </Typography>
          )}

          <AttendantAvatarList
            attendants={attendantRegistrations.map(
              ({ attendant }) => attendant
            )}
            sx={{ mt: 1 }}
          />
        </CardContent>
      </Card>

      <UserSessionDetailsDialog {...dialog} session={session} />
    </>
  );
};

export default UserSessionCard;
