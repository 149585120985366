import { FC, useCallback } from 'react';

import Dialog, { DialogProps, useDialog } from 'components/Dialog';
import SessionDetails from 'components/SessionDetails';
import SessionRegisterDialog from 'components/SessionRegisterDialog';
import DialogButton from 'components/DialogButton';

import { AttendantInvitationListView, SessionListView } from 'schema';
import { APP_URL } from 'const';

type Props = Omit<DialogProps, 'children' | 'title'> & {
  session: SessionListView;
  invitation?: AttendantInvitationListView;
};

const SessionDetailsDialog: FC<Props> = props => {
  const { session, invitation, ...rest } = props;
  const { id, activity, hasVacancies } = session;
  const { openDialog, onClose, ...dialog } = useDialog();

  const handleRegister = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <>
      <Dialog {...rest} title={activity.name}>
        <SessionDetails id={id} />

        {invitation && (
          <DialogButton href={`${APP_URL}${invitation.link}`}>
            Registracija
          </DialogButton>
        )}
      </Dialog>

      {hasVacancies && (
        <SessionRegisterDialog
          sessionId={id}
          invitationId={invitation?.id}
          onRegister={handleRegister}
          onClose={onClose}
          {...dialog}
        />
      )}
    </>
  );
};

export default SessionDetailsDialog;
