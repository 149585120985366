import { FC } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import AvatarSkeleton from 'components/AvatarSkeleton';

type Props = {};

const AttendantSkeleton: FC<Props> = () => (
  <ListItem disablePadding>
    <ListItemButton>
      <ListItemIcon>
        <AvatarSkeleton />
      </ListItemIcon>

      <ListItemText>
        <Skeleton />
      </ListItemText>
    </ListItemButton>
  </ListItem>
);

export default AttendantSkeleton;
