import AssignmentIcon from '@mui/icons-material/Assignment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Blank from 'components/Blank';
import SessionResultsAttendant from 'components/SessionResultsAttendant';
import SessionResultsSkeleton from 'components/SessionResultsSkeleton';

import { useSession } from 'features/Session';

type Props = {
  id: number;
};

const SessionResults = ({ id }: Props) => {
  const sessionQuery = useSession(id);

  return (
    <Box sx={{ p: 1, minHeight: 'calc(100vh - 176px)' }}>
      {!sessionQuery.data ? (
        <SessionResultsSkeleton />
      ) : sessionQuery.data.results.length > 0 ? (
        sessionQuery.data.results.map(result => (
          <SessionResultsAttendant key={result.attendant.id} group={result} />
        ))
      ) : (
        <Blank offset={192}>
          <AssignmentIcon fontSize="inherit" />
          <Typography>Rezultatų dar nėra</Typography>
        </Blank>
      )}
    </Box>
  );
};

export default SessionResults;
