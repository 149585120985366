import AttendandEditDialogContent from 'components/AttendandEditDialogContent';
import Dialog, { DialogProps } from 'components/Dialog';

import { AttendantListView } from 'schema';

type Props = Omit<DialogProps, 'title' | 'children'> & {
  attendant: AttendantListView;
};

const AttendandEditDialog = ({ attendant, ...props }: Props) => {
  return (
    <Dialog variant="back" title="Dalyvio duomenys" {...props}>
      <AttendandEditDialogContent attendant={attendant} />
    </Dialog>
  );
};

export default AttendandEditDialog;
