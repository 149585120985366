import Box from '@mui/material/Box';
import ImageIcon from '@mui/icons-material/Image';

type Props = {
  height?: number;
};

const NoImage = ({ height = 240 }: Props) => {
  return (
    <Box
      sx={{
        height,
        width: '100%',
        bgcolor: '#f5f5f5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'text.secondary',
        fontSize: '72px',
      }}>
      <ImageIcon fontSize="inherit" />
    </Box>
  );
};

export default NoImage;
