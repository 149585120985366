import { FC } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import NoImage from 'components/NoImage';
import Skeleton from '@mui/material/Skeleton';
import AvatarSkeleton from 'components/AvatarSkeleton';

const UserSessionCardSkeleton: FC = () => (
  <Card elevation={0} sx={{ border: '1px solid #e0e0e0' }}>
    <NoImage />

    <CardContent>
      <Typography gutterBottom>
        <Link underline="hover">
          <Skeleton sx={{ width: '88%' }} />
        </Link>
      </Typography>

      <Typography variant="h6">
        <Skeleton sx={{ width: '45%' }} />
      </Typography>

      <Typography color="text.secondary">
        <Skeleton sx={{ width: '55%' }} />
      </Typography>

      <AvatarSkeleton />
    </CardContent>
  </Card>
);

export default UserSessionCardSkeleton;
