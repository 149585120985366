import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { messageIndex, messageShow, MessageShowInput } from 'schema';

export const messageKeys = {
  all: [{ scope: 'messages' }] as const,
  lists: () => [{ ...messageKeys.all[0], entity: 'list' }] as const,
  list: () => [{ ...messageKeys.lists()[0] }] as const,

  details: () => [{ ...messageKeys.all[0], entity: 'detail' }] as const,
  detail: (params: MessageShowInput) =>
    [{ ...messageKeys.details()[0], params }] as const,
};

type MessageDetailsContext = QueryFunctionContext<
  ReturnType<(typeof messageKeys)['detail']>
>;

export const useMessages = () =>
  useQuery({ queryKey: messageKeys.list(), queryFn: messageIndex });

export const useMessage = (message: number) =>
  useQuery({
    queryKey: messageKeys.detail({ message }),
    queryFn: async ({ queryKey: [{ params }] }: MessageDetailsContext) =>
      await messageShow(params),
  });
