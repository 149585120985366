import { FC } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {};

const AppSplash: FC<Props> = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
    }}>
    <CircularProgress size={72} />
  </Box>
);

export default AppSplash;
