import { FC, useCallback } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import DetailsSection from 'components/DetailsSection';
import SessionDetailsSchedule from 'components/SessionDetailsSchedule';

import { useSession } from 'features/Session';
import { useAttendants } from 'features/Attendant';
import { useAttendantRegistration } from 'components/SessionRegisterDialog';

type Props = {
  id: number;
};

const SessionRegister: FC<Props> = ({ id }) => {
  const sessionQuery = useSession(id);
  const attendantsQuery = useAttendants();
  const { toggle, selected } = useAttendantRegistration();

  const handleChange = useCallback(
    (id: number) => () => {
      toggle(id);
    },
    [toggle]
  );

  if (!sessionQuery.data || !attendantsQuery.data) return <>Kraunasi...</>;

  const session = sessionQuery.data;
  const attendands = attendantsQuery.data;
  const { coach, price, address } = session;
  const { user, club } = coach;

  return (
    <>
      <DetailsSection noPadding>
        <Box sx={{ p: 2 }}>
          <Typography sx={{ fontWeight: 'bold' }}>Užsiėmimas</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {session.name}
          </Typography>
        </Box>

        <Box sx={{ p: 2 }}>
          <Typography sx={{ fontWeight: 'bold' }}>
            {user.firstName} {user.lastName}
          </Typography>

          <Typography color="text.secondary">{club.name}</Typography>
          <Typography color="text.secondary">{user.phone}</Typography>
          <Typography color="text.secondary">{user.email}</Typography>
        </Box>

        <SessionDetailsSchedule session={session} />

        <Box sx={{ p: 2 }}>
          <Typography sx={{ fontWeight: 'bold' }}>Kaina</Typography>
          <Typography color="text.secondary">
            {price.toFixed(2)} €/mėn
          </Typography>
        </Box>

        <Box sx={{ p: 2 }}>
          <Typography sx={{ fontWeight: 'bold' }}>Vieta</Typography>
          <Typography color="text.secondary">{address}</Typography>
        </Box>
      </DetailsSection>

      <DetailsSection>
        <Typography>Vaikai registruojami į būrelį</Typography>

        {attendands.map(({ id, firstName, lastName }) => (
          <FormControlLabel
            key={id}
            control={
              <Checkbox checked={selected(id)} onChange={handleChange(id)} />
            }
            label={`${firstName} ${lastName}`}
          />
        ))}

        <Box sx={{ pt: 2 }}>
          <Typography>Sutarties sąlygos</Typography>
          <FormControlLabel
            key={id}
            control={<Checkbox />}
            label={
              <>
                Sutinku su būrelio <Link>sutartimi</Link>
              </>
            }
          />
        </Box>
      </DetailsSection>
    </>
  );
};

export default SessionRegister;
