import { createContext, FC, useCallback, useContext, useState } from 'react';

import DialogButton from 'components/DialogButton';
import Dialog, { DialogProps } from 'components/Dialog';
import SessionRegister from 'components/SessionRegister';

import { useAcceptance } from 'features/Invitation';

type InvitationContextType = {
  attendants: number[];
  toggle: (id: number) => void;
  selected: (id: number) => boolean;
};

type Props = Omit<DialogProps, 'title' | 'children' | 'id'> & {
  sessionId: number;
  invitationId?: number;
  onRegister?: () => void;
};

const InvitationContext = createContext<InvitationContextType>({
  attendants: [],
  toggle: () => {},
  selected: () => false,
});

export const useAttendantRegistration = () => useContext(InvitationContext);

const SessionRegisterDialog: FC<Props> = props => {
  const { sessionId, invitationId, onRegister, ...rest } = props;
  const [attendants, setAttendants] = useState<number[]>([]);
  const acceptance = useAcceptance();
  // const register = useRegisterToSession();

  const toggle = useCallback((id: number) => {
    setAttendants(current =>
      current.includes(id)
        ? current.filter(item => item !== id)
        : [...current, id]
    );
  }, []);

  const selected = useCallback(
    (id: number) => attendants.includes(id),
    [attendants]
  );

  const handleSubmit = useCallback(() => {
    if (invitationId) {
      const vars = {
        invitation: invitationId,
        form: {
          attendants: attendants.map(id => ({ id })),
        },
      };

      acceptance.mutate(vars, {
        onSuccess: ({ removed }) => {
          if (onRegister && removed) {
            onRegister();
          }
        },
      });
    } else {
      // const vars = {
      //   session: sessionId,
      //   form: {
      //     attendants: attendants.map(id => ({ id })),
      //   },
      // };
      // register.mutate(vars, {
      //   onSuccess: () => {
      //     onRegister && onRegister();
      //   },
      // });
    }
  }, [acceptance, attendants, invitationId, onRegister]);

  return (
    <Dialog variant="back" title="Registracija" {...rest}>
      <InvitationContext.Provider value={{ attendants, toggle, selected }}>
        <SessionRegister id={sessionId} />

        <DialogButton onClick={handleSubmit} loading={acceptance.isPending}>
          Registruotis
        </DialogButton>
      </InvitationContext.Provider>
    </Dialog>
  );
};

export default SessionRegisterDialog;
