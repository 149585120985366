import { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  index: number;
  value: number;
};

const TabPanel = ({ children, value, index, ...other }: Props) => (
  <div hidden={value !== index} {...other}>
    {value === index && children}
  </div>
);

export default TabPanel;
