import { format } from 'date-fns/format';

import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Link from '@mui/material/Link';

import { ParentInvoiceListView } from 'schema';
import { useDownload } from 'lib';

type Props = {
  open: boolean;
  invoice: ParentInvoiceListView;
};

const colSpan = 3;

const InvoiceRowDetails = ({ open, invoice }: Props) => {
  const { items, totalItems, total, discount, paymentDate, status } = invoice;

  const [download, isDownloading] = useDownload(
    `/api/v1/invoices/${invoice.id}/pdf`
  );

  return (
    <Collapse in={open} unmountOnExit>
      <Box sx={{ margin: 1 }}>
        <Box>
          Sąskaita:{' '}
          <Link onClick={download}>
            {isDownloading ? 'Palaukite...' : invoice.invoiceNumber}
          </Link>
        </Box>

        {paymentDate && status !== 'paid' && (
          <Box>Apmokėti iki: {format(paymentDate, 'yyyy-MM-dd')}</Box>
        )}

        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              <TableCell component="th">Paslauga</TableCell>

              <TableCell component="th" align="center">
                Kiekis
              </TableCell>

              <TableCell component="th" align="center">
                Kaina
              </TableCell>

              <TableCell component="th" align="right">
                Suma
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {items.map((row, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Box>{row.name}</Box>

                  <Box component="div" color="text.secondary">
                    {row.attendants
                      .map(
                        ({ firstName, lastName }) =>
                          `${firstName} ${lastName.charAt(0)}.`
                      )
                      .join(', ')}
                  </Box>
                </TableCell>

                <TableCell align="center">{row.amount}</TableCell>
                <TableCell align="center">{row.price.toFixed(2)} €</TableCell>
                <TableCell align="right">{row.total.toFixed(2)} €</TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell align="right" colSpan={colSpan}>
                <strong>{!discount ? 'Viso mokėti' : 'Viso'}</strong>
              </TableCell>

              <TableCell align="right">
                <strong>
                  {!discount ? total.toFixed(2) : totalItems.toFixed(2)} €
                </strong>
              </TableCell>
            </TableRow>

            {discount ? (
              <>
                <TableRow>
                  <TableCell colSpan={colSpan} align="right">
                    <strong>Nuolaida</strong>
                  </TableCell>

                  <TableCell align="right">
                    <strong>{discount.toFixed(2)} €</strong>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={colSpan} align="right">
                    <strong>Viso mokėti</strong>
                  </TableCell>

                  <TableCell align="right">
                    <strong>{total.toFixed(2)} €</strong>
                  </TableCell>
                </TableRow>
              </>
            ) : null}
          </TableBody>
        </Table>
      </Box>
    </Collapse>
  );
};

export default InvoiceRowDetails;
