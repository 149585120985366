import AttendandEditDialogForm from 'components/AttendandEditDialogForm';
import AttendandEditDialogFormSkeleton from 'components/AttendandEditDialogFormSkeleton';

import { useAttendant } from 'features/Attendant';
import { AttendantListView } from 'schema';

type Props = {
  attendant: AttendantListView;
};

const AttendandEditDialogContent = ({ attendant }: Props) => {
  const query = useAttendant(attendant.id);

  return query.data ? (
    <AttendandEditDialogForm attendant={query.data} />
  ) : (
    <AttendandEditDialogFormSkeleton />
  );
};

export default AttendandEditDialogContent;
