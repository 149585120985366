import { FC } from 'react';

import Paper, { PaperProps } from '@mui/material/Paper';

type Props = PaperProps & {
  noPadding?: boolean;
};

const DetailsSection: FC<Props> = ({ sx = [], noPadding = false, ...rest }) => (
  <Paper
    sx={[{ m: 2 }, !noPadding && { p: 2 }, ...(Array.isArray(sx) ? sx : [sx])]}
    elevation={0}
    {...rest}
  />
);

export default DetailsSection;
