import { FC } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Skeleton from '@mui/material/Skeleton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';

const SessionInvitationsSkeleton: FC = () => {
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        <Skeleton sx={{ width: '75%' }} />
      </Typography>

      <Paper elevation={0}>
        <List disablePadding>
          <ListItem>
            <ListItemIcon>
              <Skeleton variant="rectangular" width={40} height={40} />
            </ListItemIcon>

            <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />

            <ListItemSecondaryAction>
              <IconButton>
                <MoreVertIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Paper>
    </Box>
  );
};

export default SessionInvitationsSkeleton;
