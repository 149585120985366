import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

type Props = {
  width?: number;
};

const TextFieldSkeleton = ({ width = 150 }: Props) => (
  <Box>
    <Skeleton width={width} height={15} />
    <Skeleton width="100%" />
  </Box>
);

export default TextFieldSkeleton;
