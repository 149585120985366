import { FC } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import AvatarSkeleton from 'components/AvatarSkeleton';

type Props = {};

const TimetableScheduleTimeSlotSkeleton: FC<Props> = () => (
  <Box sx={{ pl: 3, mb: 2 }}>
    <Paper>
      <Box sx={{ ml: -3, display: 'flex', alignItems: 'center' }}>
        <Paper
          sx={{
            pt: 2,
            pb: 2,
            textAlign: 'center',
            fontSize: '1.25em',
            mr: 1,
            bgcolor: '#505881',
            color: '#ffffff',
            width: '70px',
          }}>
          <Box sx={{ height: '30px' }} />
        </Paper>

        <Box
          sx={{
            mt: 1,
            mb: 1,
            pt: 1,
            pb: 1,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}>
          <Skeleton sx={{ width: '75%' }} />
          <AvatarSkeleton />
        </Box>
      </Box>
    </Paper>
  </Box>
);

export default TimetableScheduleTimeSlotSkeleton;
