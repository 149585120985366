import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  invoiceMyInvoices,
  invoiceStartPayment,
  ParentInvoiceListView,
  paymentCancel,
  paymentConfirm,
  PaymentPayload,
} from 'schema';

export const invoiceKeys = {
  all: [{ scope: 'invoices' }] as const,
  lists: () => [{ ...invoiceKeys.all[0], entity: 'list' }] as const,
  list: () => [{ ...invoiceKeys.lists()[0] }] as const,
};

export const useMyInvoices = () =>
  useQuery({
    queryKey: invoiceKeys.list(),
    queryFn: invoiceMyInvoices,
    refetchInterval: 2000,
  });

export const useInvoiceStartPayment = (invoice: number) =>
  useMutation({
    mutationFn: () => invoiceStartPayment({ invoice }),

    onSuccess: ({ redirectUrl }) => {
      window.location.replace(redirectUrl);
    },
  });

export const useConfirmPayment = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (form: PaymentPayload) => paymentConfirm({ form }),

    onSuccess: invoice => {
      client.setQueryData<ParentInvoiceListView[]>(
        invoiceKeys.list(),
        current =>
          current?.map(item => (item.id === invoice.id ? invoice : item)) ?? []
      );
    },
  });
};

export const useCancelPayment = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (form: PaymentPayload) => paymentCancel({ form }),

    onSuccess: invoice => {
      client.setQueryData<ParentInvoiceListView[]>(
        invoiceKeys.list(),
        current =>
          current?.map(item => (item.id === invoice.id ? invoice : item)) ?? []
      );
    },
  });
};
