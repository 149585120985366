import Avatar, { AvatarProps } from '@mui/material/Avatar';

import { AttendantListView, UserListView } from 'schema';

type Props = AvatarProps & {
  user: UserListView | AttendantListView;
};

const UserAvatar = ({ user, ...props }: Props) => {
  const initials = `${user.firstName?.charAt(0).toUpperCase()}${user.lastName
    ?.charAt(0)
    .toUpperCase()}`;

  return <Avatar {...props}>{initials}</Avatar>;
};

export default UserAvatar;
