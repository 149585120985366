import { FC, useCallback, useState } from 'react';

import { startOfWeek } from 'date-fns/startOfWeek';
import { addDays } from 'date-fns/addDays';
import { subDays } from 'date-fns/subDays';
import { isSameDay } from 'date-fns/isSameDay';
import { format } from 'date-fns/format';

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import TimetableDaySelector from 'components/TimetableDaySelector';

import { usePeriodSummary } from 'features/Schedule';
import { locale } from 'const';
import { upperCaseFirst, useIsDesktop } from 'lib';

type Props = {
  value: Date;
  onChange: (date: Date) => void;
};

const TimetableDates: FC<Props> = ({ value, onChange }) => {
  const desktop = useIsDesktop();

  const [week, setWeek] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );

  const periodSummaryQuery = usePeriodSummary(week);

  const handlePreviousWeek = useCallback(() => {
    setWeek(current => subDays(current, 7));
  }, []);

  const handleNextWeek = useCallback(() => {
    setWeek(current => addDays(current, 7));
  }, []);

  const controls = [];
  for (let i = 0; i < 7; i++) {
    const date = addDays(week, i);

    controls.push(
      <TimetableDaySelector
        key={i}
        selected={isSameDay(date, value)}
        onClick={onChange}
        date={date}
        hasSessions={periodSummaryQuery.data?.[i].hasSessions ?? false}
      />
    );
  }

  return (
    <Paper
      square
      sx={[
        {
          pt: 1,
          pb: 1,
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 100,
        },
        desktop && {
          mt: 9,
          width: 800,
          left: '50%',
          ml: '-400px',
        },
      ]}>
      <Box sx={{ textAlign: 'center', fontSize: '1.25em', mb: 1 }}>
        <strong>{upperCaseFirst(format(week, 'LLLL', { locale }))}</strong>
      </Box>

      <Box sx={{ display: 'flex' }}>
        <IconButton onClick={handlePreviousWeek}>
          <ArrowLeftIcon />
        </IconButton>

        <Box
          sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-evenly' }}>
          {controls}
        </Box>

        <IconButton onClick={handleNextWeek}>
          <ArrowRightIcon />
        </IconButton>
      </Box>
    </Paper>
  );
};

export default TimetableDates;
