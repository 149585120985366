import { FC, useState } from 'react';

import Box from '@mui/material/Box';
import TimetableDates from 'components/TimetableDates';
import TimetableSchedule from 'components/TimetableSchedule';

const Timetable: FC = () => {
  const [selectedDay, setSelectedDay] = useState(new Date());

  return (
    <Box>
      <TimetableDates value={selectedDay} onChange={setSelectedDay} />
      <TimetableSchedule day={selectedDay} />
    </Box>
  );
};

export default Timetable;
