import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Box from '@mui/material/Box';
import Paper, { PaperProps } from '@mui/material/Paper';
import TimetableScheduleSession from 'components/TimetableScheduleSession';

import { DayScheduleSlot } from 'schema';

type Props = PaperProps & {
  timeSlot: DayScheduleSlot;
};

const TimetableScheduleTimeSlot = ({ timeSlot, ...props }: Props) => (
  <Box sx={{ pl: 3, mb: 2, overflow: 'clip' }}>
    <Paper {...props}>
      <Box sx={{ ml: -3, display: 'flex', alignItems: 'center' }}>
        <Paper
          sx={{
            pt: 2,
            pb: 2,
            pl: 1,
            pr: 1,
            textAlign: 'center',
            fontSize: '1.25em',
            mr: 1,
            bgcolor: '#505881',
            color: '#ffffff',
            minWidth: '75px',
            minHeight: '62px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {timeSlot.startTime === '__ade' ? (
            <MoreHorizIcon />
          ) : (
            timeSlot.startTime
          )}
        </Paper>

        <Box sx={{ mt: 1, mb: 1, display: 'flex', flexDirection: 'column' }}>
          {timeSlot.sessions.map(session => (
            <TimetableScheduleSession session={session} key={session.id} />
          ))}
        </Box>
      </Box>
    </Paper>
  </Box>
);

export default TimetableScheduleTimeSlot;
