import EmailIcon from '@mui/icons-material/Email';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import PersonIcon from '@mui/icons-material/Person';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import { useNavValue } from 'lib';

const NavMobile = () => {
  const value = useNavValue();

  return (
    <Paper
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
      elevation={3}>
      <BottomNavigation showLabels value={value}>
        <BottomNavigationAction
          href="/"
          label="Tvarkaraštis"
          icon={<CalendarMonthIcon />}
        />

        <BottomNavigationAction
          href="/invoices"
          label="Sąskaitos"
          icon={<ReceiptIcon />}
        />

        <BottomNavigationAction
          href="/messages"
          label="Pranešimai"
          icon={<EmailIcon />}
        />

        <BottomNavigationAction
          href="/sessions"
          label="Būreliai"
          icon={<BeachAccessIcon />}
        />

        <BottomNavigationAction
          href="/account"
          label="Paskyra"
          icon={<PersonIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default NavMobile;
