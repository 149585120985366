import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

import { useNotification } from 'components/Notifications';
import { STORAGE_ACCESS_TOKEN } from 'const';
import { useCallback, useState } from 'react';
import { authRefreshToken } from 'schema';

export type ApiResponse<D> = {
  errorCode: number | null;
  data: D;
  timestamp: string;
  message?: string | null;
};

type Subscriber = (ok: boolean) => void;

type FilesMap = {
  [key: string]: File | File[] | null;
};

const http = axios.create();
const expiredMessage = 'expired_token';
let isRefreshing = false;
let subscribers: Subscriber[] = [];

function onRefreshed(ok: boolean) {
  subscribers.forEach(cb => cb(ok));
}

function subscribeTokenRefresh(cb: Subscriber) {
  subscribers.push(cb);
}

http.interceptors.request.use(
  config => {
    const token = localStorage.getItem(STORAGE_ACCESS_TOKEN);
    if (!token) return config;

    config['headers'] = config.headers ?? {};

    config.headers['Authorization'] = `Bearer ${token}`;

    return config;
  },
  error => Promise.reject(error)
);

http.interceptors.response.use(
  (response: AxiosResponse<ApiResponse<any>>) => response,
  (error: any) => {
    const originalRequest = error.config as AxiosRequestConfig & {
      _retry?: boolean;
    };

    if (!originalRequest) return;

    if (
      error.response.status === 401 &&
      error.response.data?.errorCode === expiredMessage &&
      !originalRequest._retry
    ) {
      const result = new Promise((resolve, reject) => {
        subscribeTokenRefresh((ok: boolean): void => {
          if (!ok) {
            reject(error);
          } else {
            const token = localStorage.getItem(STORAGE_ACCESS_TOKEN);

            if (!token) return reject('No token');

            if (!originalRequest.headers) {
              originalRequest.headers = {};
            }

            originalRequest.headers['Authorization'] = `Bearer ${token}`;

            originalRequest._retry = true;
            resolve(axios(originalRequest));
          }
        });
      });

      if (!isRefreshing) {
        isRefreshing = true;

        authRefreshToken()
          .then(({ accessToken }) => {
            localStorage.setItem(STORAGE_ACCESS_TOKEN, accessToken);

            isRefreshing = false;
            onRefreshed(true);
            subscribers = [];
          })
          .catch(() => {
            onRefreshed(false);
          });
      }

      return result;
    }

    return Promise.reject(error);
  }
);

export function useErrorHandler(defaultMessage: string = 'Įvyko klaida') {
  const { pop } = useNotification();

  return (error: AxiosError<ApiResponse<any>>) => {
    const responseMessage = error.response?.data.message;

    pop(
      responseMessage
        ? responseMessage
        : error.message
        ? error.message
        : defaultMessage
    );
  };
}

type UseDownload = [() => void, boolean];

export function useDownload(url: string): UseDownload {
  const [loading, setLoading] = useState(false);
  const handleError = useErrorHandler();

  const download = useCallback(() => {
    if (loading) return;

    setLoading(true);

    http
      .get(url, { responseType: 'blob' })
      .then(response => {
        setLoading(false);

        const fileName =
          response.headers['content-disposition'].split('filename=')[1];

        saveAs(response.data, fileName);
      })
      .catch(error => {
        setLoading(false);
        handleError(error);
      });
  }, [handleError, loading, url]);

  return [download, loading];
}

export function makeFormData(data: unknown, files: FilesMap | null) {
  const formData = new FormData();

  formData.append('data', JSON.stringify(data));

  if (files) {
    Object.keys(files).forEach(name => {
      const file = files[name];

      if (file) {
        if (Array.isArray(file)) {
          file.forEach(item => {
            formData.append(`${name}[]`, item);
          });
        } else {
          formData.append(name, file);
        }
      }
    });
  }

  return formData;
}

export default http;
