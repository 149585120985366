import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  authChangePassword,
  authCheckRecovery,
  AuthCheckRecoveryInput,
  authCreateToken,
  authMe,
  authRevoke,
  authStartRecovery,
  authUpdateMe,
  CreateTokenForm,
  NewPassword,
  PasswordRecoveryForm,
  UpdateMyDetails,
} from 'schema';

import { STORAGE_ACCESS_TOKEN } from 'const';
import { useErrorHandler } from 'lib';
import { useNotification } from 'components/Notifications';

export const authKeys = {
  all: [{ scope: 'auth' }] as const,
  me: () => [{ ...authKeys.all[0], entity: 'me' }] as const,

  recoveryStatuses: () =>
    [{ ...authKeys.all[0], entity: 'recovery-status' }] as const,
  recoveryStatus: (params: AuthCheckRecoveryInput) =>
    [{ ...authKeys.recoveryStatuses()[0], params }] as const,
};

type RecoveryStatusContext = QueryFunctionContext<
  ReturnType<(typeof authKeys)['recoveryStatus']>
>;

export const useMe = () =>
  useQuery({
    queryKey: authKeys.me(),
    queryFn: authMe,
    enabled: !!localStorage.getItem(STORAGE_ACCESS_TOKEN),
    retry: false,
    refetchOnWindowFocus: false,
  });

export const useRecoveryStatus = (token: string) =>
  useQuery({
    queryKey: authKeys.recoveryStatus({ token }),
    queryFn: async ({ queryKey: [{ params }] }: RecoveryStatusContext) =>
      await authCheckRecovery(params),
  });

export const useUpdateMe = () => {
  const client = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: UpdateMyDetails) => authUpdateMe({ form }),
    onError: useErrorHandler(),

    onSuccess: data => {
      client.setQueryData(authKeys.me(), data);
      pop('Išsaugota');
    },
  });
};

export const useLogin = () =>
  useMutation({
    mutationFn: (form: CreateTokenForm) => authCreateToken({ form }),
    onSuccess: ({ accessToken }) => {
      localStorage.setItem(STORAGE_ACCESS_TOKEN, accessToken);
    },
  });

export const useLogout = () =>
  useMutation({
    mutationFn: authRevoke,
    onSuccess: () => {
      localStorage.removeItem(STORAGE_ACCESS_TOKEN);
    },
    onError: useErrorHandler(),
  });

export const useStartRecovery = () =>
  useMutation({
    mutationFn: (form: PasswordRecoveryForm) => authStartRecovery({ form }),
    onError: useErrorHandler(),
  });

export const useChangePassword = () =>
  useMutation({
    mutationFn: (form: NewPassword) => authChangePassword({ form }),
    onError: useErrorHandler(),
  });
