import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import UserSessionCard from 'components/UserSessionCard';
import MySessionsSkeleton from 'components/MySessionsSkeleton';

import { useMySessions } from 'features/Session';

const MySessions = () => {
  const mySessionsQuery = useMySessions();

  if (!mySessionsQuery.data) return <MySessionsSkeleton />;

  const sessions = mySessionsQuery.data;

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Lankomi būreliai
      </Typography>

      {sessions.length > 0 ? (
        sessions.map((userSession, i) => (
          <UserSessionCard key={i} session={userSession} sx={{ mb: 2 }} />
        ))
      ) : (
        <Box>Dar nelankote jokių būrelių.</Box>
      )}
    </Box>
  );
};

export default MySessions;
