import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { addDays } from 'date-fns/addDays';

import {
  scheduleDaySchedule,
  ScheduleDayScheduleInput,
  schedulePeriodSummary,
  SchedulePeriodSummaryInput,
} from 'schema';

export const scheduleKeys = {
  all: [{ scope: 'schedule' }] as const,

  schedules: () => [{ ...scheduleKeys.all[0], entity: 'schedule' }] as const,
  schedule: (params: ScheduleDayScheduleInput) =>
    [{ ...scheduleKeys.schedules()[0], params }] as const,

  summaries: () => [{ ...scheduleKeys.all[0], entity: 'summary' }] as const,
  summary: (params: SchedulePeriodSummaryInput) =>
    [{ ...scheduleKeys.summaries()[0], params }] as const,
};

type DayScheduleListContext = QueryFunctionContext<
  ReturnType<(typeof scheduleKeys)['schedule']>
>;

type DayScheduleSummaryContext = QueryFunctionContext<
  ReturnType<(typeof scheduleKeys)['summary']>
>;

export const useDaySchedule = (date: Date) =>
  useQuery({
    queryKey: scheduleKeys.schedule({
      date,
    }),
    queryFn: async ({ queryKey: [{ params }] }: DayScheduleListContext) =>
      scheduleDaySchedule(params),
  });

export const usePeriodSummary = (start: Date) =>
  useQuery({
    queryKey: scheduleKeys.summary({
      start,
      end: addDays(start, 7),
    }),
    queryFn: async ({ queryKey: [{ params }] }: DayScheduleSummaryContext) =>
      await schedulePeriodSummary(params),
  });
