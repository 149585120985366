import { FC } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import UserAvatar from 'components/UserAvatar';
import MessageDetails from 'components/MessageDetails';
import Gallery from 'components/Gallery';

import { useMessage } from 'features/Message';

type Props = {
  messageId: number;
};

const MessageDialogContent: FC<Props> = ({ messageId }) => {
  const messageQuery = useMessage(messageId);

  if (!messageQuery.data) return <>Kraunasi...</>;

  const message = messageQuery.data;

  return (
    <Box sx={{ minHeight: 'calc(100vh - 128px)', p: 2 }}>
      <Typography variant="h6">{message.title}</Typography>

      <Box sx={{ display: 'flex', mt: 1, alignItems: 'center' }}>
        <UserAvatar user={message.author} sx={{ mr: 1 }} />

        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          <MessageDetails message={message} />
        </Typography>
      </Box>

      {message.images && message.images.length > 0 && (
        <Gallery images={message.images} />
      )}

      <Typography sx={{ mt: 2 }}>{message.content}</Typography>
    </Box>
  );
};

export default MessageDialogContent;
