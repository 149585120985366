import { date, object, string } from 'yup';

import Stack from '@mui/material/Stack';
import FormDatePicker from 'components/FormDatePicker';
import FormTextField from 'components/FormTextField';

export type FormFields = {
  firstName: string;
  lastName: string;
  dateOfBirth: Date | null;
};

export const schema = object({
  firstName: string().required('Prašome įvesti vardą'),
  lastName: string().required('Prašome įvesti pavardę'),
  dateOfBirth: date().nullable().required('Prašome įvesti gimimo datą'),
});

const AttendantFormFields = () => (
  <Stack spacing={4}>
    <FormTextField name="firstName" label="Vardas" />
    <FormTextField name="lastName" label="Pavardė" />
    <FormDatePicker name="dateOfBirth" label="Gimimo data" />
  </Stack>
);

export default AttendantFormFields;
