import { FC } from 'react';

import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MessagesSkeleton from 'components/MessagesSkeleton';
import MessageCard from 'components/MessageCard';
import Blank from 'components/Blank';

import { useMessages } from 'features/Message';

const Messages: FC = () => {
  const messagesQuery = useMessages();

  if (!messagesQuery.data) return <MessagesSkeleton />;

  const messages = messagesQuery.data;

  return (
    <Box sx={{ p: 1 }}>
      {messages.length > 0 ? (
        messages.map(message => (
          <MessageCard message={message} key={message.id} sx={{ mb: 1 }} />
        ))
      ) : (
        <Blank>
          <SpeakerNotesOffIcon fontSize="inherit" />
          <Typography>Pranešimų dar nėra</Typography>
        </Blank>
      )}
    </Box>
  );
};

export default Messages;
