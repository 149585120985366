import { FC, useCallback, MouseEvent } from 'react';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

type Props = DialogProps & {
  title?: string;
  text?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
};

export type ConfirmDialogProps = Props;

const ConfirmDialog: FC<Props> = props => {
  const {
    title = 'Patvirtinimas',
    text = 'Ar tikrai norite atlikti šį veiksmą?',
    onCancel,
    onConfirm,
    ...rest
  } = props;

  const handleCancel = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      onCancel && onCancel();
    },
    [onCancel]
  );

  const handleConfirm = useCallback(() => {
    onConfirm && onConfirm();
  }, [onConfirm]);

  return (
    <Dialog {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{text}</DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Ne
        </Button>

        <Button onClick={handleConfirm}>Taip</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
