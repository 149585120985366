import Dialog, { DialogProps } from 'components/Dialog';
import AccountSettingsDialogForm from 'components/AccountSettingsDialogForm';
import AccountSettingsDialogFormSkeleton from 'components/AccountSettingsDialogFormSkeleton';

import { useMe } from 'features/Auth';

type Props = Omit<DialogProps, 'title' | 'children'>;

const AccountSettingsDialog = (props: Props) => {
  const me = useMe();

  return (
    <Dialog title="Paskyros nustatymai" {...props}>
      {me.data ? (
        <AccountSettingsDialogForm user={me.data} />
      ) : (
        <AccountSettingsDialogFormSkeleton />
      )}
    </Dialog>
  );
};

export default AccountSettingsDialog;
