import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { formatAgo } from 'lib';
import { SessionResultView } from 'schema';

type Props = {
  result: SessionResultView;
};

const SessionResultsResult = ({ result }: Props) => (
  <Card sx={{ p: 1, mb: 1 }}>
    <Typography sx={{ color: 'text.secondary' }} gutterBottom>
      {formatAgo(result.created)}
    </Typography>

    <Typography>{result.text}</Typography>
  </Card>
);

export default SessionResultsResult;
