import { FC } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import SessionInvitation from 'components/SessionInvitation';
import SessionInvitationsSkeleton from 'components/SessionInvitationsSkeleton';

import { useInvitations } from 'features/Invitation';

const SessionInvitations: FC = () => {
  const invitationsQuery = useInvitations();

  if (!invitationsQuery.data) return <SessionInvitationsSkeleton />;

  const invitations = invitationsQuery.data;

  if (invitations.length === 0) return null;

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Pakvietimai
      </Typography>

      <Paper elevation={0}>
        <List disablePadding>
          {invitationsQuery.data.map((invitation, i) => (
            <SessionInvitation
              invitation={invitation}
              key={invitation.id}
              last={i === invitationsQuery.data.length - 1}
            />
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default SessionInvitations;
