import { FC } from 'react';

import Dialog, { DialogProps } from 'components/Dialog';
import MessageDialogContent from 'components/MessageDialogContent';

type Props = Omit<DialogProps, 'children' | 'title'> & {
  messageId: number;
};

const MessageDialog: FC<Props> = ({ messageId, ...rest }) => {
  return (
    <Dialog title="Pranešimas" {...rest}>
      <MessageDialogContent messageId={messageId} />
    </Dialog>
  );
};

export default MessageDialog;
