import { ReactNode } from 'react';

import Box, { BoxProps } from '@mui/material/Box';

type Props = BoxProps & {
  children?: ReactNode;
  offset?: number;
};

const Blank = ({ children, offset = 56, sx = [], ...rest }: Props) => (
  <Box
    sx={[
      {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${offset}px)`,
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...rest}>
    <Box
      sx={{
        color: 'text.secondary',
        textAlign: 'center',
        fontSize: '3em',
      }}>
      {children}
    </Box>
  </Box>
);

export default Blank;
