import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { object, ref, string } from 'yup';

import LockIcon from '@mui/icons-material/Lock';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import AppSplash from 'components/AppSplash';
import AuthLogo from 'components/AuthLogo';
import FormTextField from 'components/FormTextField';
import FullScreen from 'components/FullScreen';

import { useNotification } from 'components/Notifications';
import { useChangePassword, useRecoveryStatus } from 'features/Auth';
import { useIsDesktop } from 'lib';

type Fields = {
  password: string;
  confirm: string;
};

type Props = {
  token: string;
};

const initial = {
  password: '',
  confirm: '',
};

const schema = object({
  password: string().required('Prašome įvesti naują slaptažodį'),
  confirm: string()
    .required('Prašome pakartoti slaptažodį')
    .oneOf([ref('password'), ''], 'Įvesti slaptažodžiai nesutampa'),
});

const AuthChangePassword = ({ token }: Props) => {
  const desktop = useIsDesktop();
  const status = useRecoveryStatus(token);
  const change = useChangePassword();
  const { pop } = useNotification();
  const [, setParams] = useSearchParams();

  const handleSubmit = useCallback(
    ({ password }: Fields) => {
      change.mutate(
        { token, password },
        {
          onSuccess: () => {
            setParams();
            pop('Slaptažodis pakeistas, galite prisijungti');
          },
        }
      );
    },
    [change, pop, setParams, token]
  );

  if (!status.data) return <AppSplash />;

  return (
    <FullScreen sx={{ minHeight: '100vh' }}>
      <Box sx={{ width: desktop ? '500px' : '100%' }}>
        <AuthLogo title="Naujo slaptažodžio nustatymas" />

        {status.data.valid ? (
          <Formik<Fields>
            initialValues={initial}
            onSubmit={handleSubmit}
            validationSchema={schema}>
            <Form>
              <Stack spacing={4}>
                <FormTextField
                  name="password"
                  type="password"
                  label="Naujas slaptažodis"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <FormTextField
                  name="confirm"
                  type="password"
                  label="Pakartokite slaptažodį"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <LoadingButton
                  loading={change.isPending}
                  variant="contained"
                  type="submit">
                  Keisti
                </LoadingButton>
              </Stack>
            </Form>
          </Formik>
        ) : (
          <Alert variant="filled" severity="error">
            Atsiprašome, bet ši nuoroda nebegalioja
          </Alert>
        )}
      </Box>
    </FullScreen>
  );
};

export default AuthChangePassword;
