import React, { FC, ReactNode } from 'react';
import { LinkProps } from '@mui/material/Link';

import LinkBehavior from 'components/LinkBehavior';

import { createTheme, ThemeProvider } from '@mui/material/styles';

type Props = {
  children: ReactNode;
};

const theme = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
});

const Theme: FC<Props> = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
