import { forwardRef, ForwardRefRenderFunction, ReactElement, Ref } from 'react';

import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

type Props = TransitionProps & {
  children: ReactElement;
};

type Component = ForwardRefRenderFunction<Ref<unknown>, Props>;

const TransitionUp: Component = (props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
);

export default forwardRef(TransitionUp);
