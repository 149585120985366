import { locale } from 'const';
import { formatDistance } from 'date-fns/formatDistance';

export enum Weekday {
  Monday = 1,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

const romanNumbers = {
  [Weekday.Monday]: 'I',
  [Weekday.Tuesday]: 'II',
  [Weekday.Wednesday]: 'III',
  [Weekday.Thursday]: 'IV',
  [Weekday.Friday]: 'V',
  [Weekday.Saturday]: 'VI',
  [Weekday.Sunday]: 'VII',
};

export function romanWeek(day: Weekday) {
  return romanNumbers[day];
}

const weekDays = {
  [Weekday.Monday]: 'Pirmadienis',
  [Weekday.Tuesday]: 'Antradienis',
  [Weekday.Wednesday]: 'Trečiadienis',
  [Weekday.Thursday]: 'Ketvirtadienis',
  [Weekday.Friday]: 'Penktadienis',
  [Weekday.Saturday]: 'Šeštadienis',
  [Weekday.Sunday]: 'Sekmadienis',
};

export function weekDayName(day: Weekday) {
  return weekDays[day];
}

export function formatAgo(date: Date) {
  return formatDistance(date, new Date(), {
    locale,
    addSuffix: true,
  });
}
