import Paper, { PaperProps } from '@mui/material/Paper';
import { useIsDesktop } from 'lib';

const BottomToolbar = ({ sx = [], ...props }: PaperProps) => {
  const desktop = useIsDesktop();

  return (
    <Paper
      square
      sx={[
        {
          p: 2,
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
        },
        desktop && { width: '800px', left: '50%', ml: '-400px' },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  );
};

export default BottomToolbar;
