import { FC } from 'react';

import Dialog, { DialogProps, useDialog } from 'components/Dialog';
import AddAttendantDialog from 'components/AddAttendantDialog';
import AttendandsDialogContent from 'components/AttendandsDialogContent';

type Props = Omit<DialogProps, 'title' | 'children'>;

const AttendandsDialog: FC<Props> = props => {
  const { openDialog, ...dialog } = useDialog();

  return (
    <>
      <Dialog title="Dalyviai" {...props}>
        <AttendandsDialogContent onAddChild={openDialog} />
      </Dialog>

      <AddAttendantDialog {...dialog} />
    </>
  );
};

export default AttendandsDialog;
