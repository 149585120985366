import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import SessionResultSkeleton from 'components/SessionResultSkeleton';

const SessionResultsSkeleton = () => {
  return (
    <Box>
      <Typography variant="caption">
        <Skeleton sx={{ width: '45%' }} />
      </Typography>

      <SessionResultSkeleton />
      <SessionResultSkeleton />
    </Box>
  );
};

export default SessionResultsSkeleton;
