import { FC } from 'react';
import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';

type Props = SkeletonProps;

const AvatarSkeleton: FC<Props> = props => (
  <Skeleton variant="circular" width={40} height={40} {...props} />
);

export default AvatarSkeleton;
