import { FC } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import DialogButton from 'components/DialogButton';
import AttendantListItem from 'components/AttendantListItem';
import AttendantSkeleton from 'components/AttendantSkeleton';
import Blank from 'components/Blank';

import { useAttendants } from 'features/Attendant';

type Props = {
  onAddChild: () => void;
};

const AttendandsDialogContent: FC<Props> = ({ onAddChild }) => {
  const attendandsQuery = useAttendants();

  if (!attendandsQuery.data)
    return (
      <Box sx={{ minHeight: 'calc(100vh - 128px)' }}>
        <AttendantSkeleton />
        <AttendantSkeleton />
      </Box>
    );

  const attendands = attendandsQuery.data;

  return (
    <>
      <Box sx={{ minHeight: 'calc(100vh - 128px)' }}>
        {attendands.length > 0 ? (
          <List>
            {attendands.map(attendant => (
              <AttendantListItem key={attendant.id} attendant={attendant} />
            ))}
          </List>
        ) : (
          <Blank offset={128}>
            <PersonIcon fontSize="inherit" />
            <Typography>Dar nėra dalyvių</Typography>
          </Blank>
        )}
      </Box>

      <DialogButton endIcon={<PersonIcon />} onClick={onAddChild}>
        Pridėti dalyvį
      </DialogButton>
    </>
  );
};

export default AttendandsDialogContent;
