import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';
import AuthNewPassword from 'components/AuthNewPassword';
import AuthGuard from 'components/AuthGuard';
import NavMobile from 'components/NavMobile';
import NavDesktop from 'components/NavDesktop';

import { useIsDesktop } from 'lib';

const Root = () => {
  const desktop = useIsDesktop();

  return (
    <AuthNewPassword>
      <AuthGuard>
        <Box
          sx={[
            {
              bgcolor: '#f9fafb',
              minHeight: '100vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            },
            desktop ? { pt: 9 } : { pb: 7 },
          ]}>
          <Box sx={{ width: desktop ? '800px' : '100%' }}>
            {desktop && <NavDesktop />}

            <Outlet />

            {!desktop && <NavMobile />}
          </Box>
        </Box>
      </AuthGuard>
    </AuthNewPassword>
  );
};

export default Root;
