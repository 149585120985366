import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextFieldSkeleton from 'components/TextFieldSkeleton';

const AttendandEditDialogFormSkeleton = () => (
  <Box sx={{ minHeight: 'calc(100vh - 128px)', p: 2 }}>
    <Stack spacing={4}>
      <TextFieldSkeleton width={100} />
      <TextFieldSkeleton width={110} />
      <TextFieldSkeleton width={150} />
    </Stack>
  </Box>
);

export default AttendandEditDialogFormSkeleton;
