import { useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

const selected = (path: string, pathname: string) => pathname.startsWith(path);

export function useNavValue() {
  const { pathname } = useLocation();

  let value;
  if (pathname === '/') {
    value = 0;
  } else if (selected('/invoices', pathname)) {
    value = 1;
  } else if (selected('/messages', pathname)) {
    value = 2;
  } else if (selected('/sessions', pathname)) {
    value = 3;
  } else if (selected('/account', pathname)) {
    value = 4;
  }

  return value;
}

export function useIsDesktop() {
  return useMediaQuery('@media (min-width:800px)');
}
