import { FC, ReactNode, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TransitionUp from 'components/TransitionUp';
import TransitionRight from 'components/TransitionRight';

import { useIsDesktop } from 'lib';

type DialogVariant = 'close' | 'back';

type Props = Omit<MuiDialogProps, 'title'> & {
  onClose: () => void;
  title: ReactNode;
  children: ReactNode;
  variant?: DialogVariant;
};

export const useDialog = () => {
  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return { open, openDialog, onClose };
};

export type DialogProps = Props;

const Dialog: FC<Props> = props => {
  const desktop = useIsDesktop();
  const { onClose, title, children, variant = 'close', ...rest } = props;
  const Transition = variant === 'close' ? TransitionUp : TransitionRight;

  return (
    <MuiDialog
      fullScreen
      onClose={onClose}
      TransitionComponent={Transition}
      {...rest}>
      <AppBar
        sx={[
          { position: 'fixed' },
          desktop && {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
        ]}>
        <Toolbar sx={[desktop && { width: 800 }]}>
          {variant === 'back' && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close">
              <ArrowBackIcon />
            </IconButton>
          )}

          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>

          {variant === 'close' && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      <Box sx={{ bgcolor: '#f9fafb' }}>
        <Box
          sx={[
            { pt: 7, pb: 9 },
            desktop && {
              pt: 8,
              width: 800,
              ml: 'auto',
              mr: 'auto',
            },
          ]}>
          {children}
        </Box>
      </Box>
    </MuiDialog>
  );
};

export default Dialog;
