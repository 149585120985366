import { FC, useCallback } from 'react';
import { format } from 'date-fns/format';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';

import { locale } from 'const';

type Props = {
  date: Date;
  selected: boolean;
  hasSessions: boolean;
  onClick: (date: Date) => void;
};

const TimetableDaySelector: FC<Props> = props => {
  const { date, selected, hasSessions, onClick } = props;

  const handleClick = useCallback(() => {
    onClick(date);
  }, [date, onClick]);

  return (
    <Box>
      <Box sx={{ color: 'text.secondary', textAlign: 'center' }}>
        {format(date, 'EEEEE', { locale })}
      </Box>

      <Badge
        variant="dot"
        color="secondary"
        overlap="circular"
        invisible={!hasSessions}>
        <Avatar
          onClick={handleClick}
          sx={[
            { width: 32, height: 32, cursor: 'pointer' },
            selected
              ? { bgcolor: 'primary.main' }
              : { color: 'text.primary', bgcolor: 'transparent' },
          ]}>
          {format(date, 'd')}
        </Avatar>
      </Badge>
    </Box>
  );
};

export default TimetableDaySelector;
