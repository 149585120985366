import { FC } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import UserSessionCardSkeleton from 'components/UserSessionCardSkeleton';

const MySessionsSkeleton: FC = () => (
  <Box sx={{ p: 2 }}>
    <Typography variant="h6" sx={{ mb: 2 }}>
      <Skeleton sx={{ width: '88%' }} />
    </Typography>

    <UserSessionCardSkeleton />
  </Box>
);

export default MySessionsSkeleton;
