import { FC, useCallback } from 'react';
import { useField } from 'formik';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';

type Props = Omit<DatePickerProps<Date>, 'onChange' | 'value'> & {
  name: string;
};

const FormDatePicker: FC<Props> = ({ name, ...rest }) => {
  const [{ value }, { error, touched }, { setValue }] = useField<Date | null>(
    name
  );
  const hasError = Boolean(error && touched);

  const handleChange = useCallback(
    (value: Date | null) => {
      setValue(value);
    },
    [setValue]
  );

  return (
    <DatePicker
      {...rest}
      localeText={{
        cancelButtonLabel: 'Atšaukti',
        okButtonLabel: 'Pasirinkti',
        toolbarTitle: 'Pasirinkite datą',
      }}
      slotProps={{
        textField: {
          variant: 'standard',
          error: hasError,
          helperText: hasError && error,
        },
      }}
      onChange={handleChange}
      value={value}
    />
  );
};

export default FormDatePicker;
