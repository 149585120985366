import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

const SessionResultSkeleton = () => (
  <Card sx={{ p: 1, mb: 1 }}>
    <Typography gutterBottom>
      <Skeleton sx={{ width: '55%' }} />
    </Typography>

    <Typography>
      <Skeleton sx={{ width: '65%' }} />
    </Typography>

    <Typography>
      <Skeleton sx={{ width: '85%' }} />
    </Typography>

    <Typography>
      <Skeleton sx={{ width: '75%' }} />
    </Typography>
  </Card>
);

export default SessionResultSkeleton;
