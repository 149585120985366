import Stack from '@mui/material/Stack';
import TextFieldSkeleton from 'components/TextFieldSkeleton';

const AccountSettingsDialogFormSkeleton = () => (
  <Stack spacing={4}>
    <TextFieldSkeleton />
    <TextFieldSkeleton />
    <TextFieldSkeleton />
    <TextFieldSkeleton />
  </Stack>
);

export default AccountSettingsDialogFormSkeleton;
