import {
  createContext,
  FC,
  ReactElement,
  useCallback,
  useContext,
  useState,
} from 'react';

import AppSplash from 'components/AppSplash';
import AuthLogin from 'components/AuthLogin';
import AuthPasswordRecovery from 'components/AuthPasswordRecovery';

import { useMe } from 'features/Auth';

type AuthContextType = {
  login: () => void;
  recover: () => void;
};

type AuthMode = 'recover' | 'login';

type Props = {
  children: ReactElement;
};

const modes = {
  login: <AuthLogin />,
  recover: <AuthPasswordRecovery />,
};

const AuthContext = createContext<AuthContextType>({
  login: () => {},
  recover: () => {},
});

export const useAuth = () => useContext(AuthContext);

const AuthGuard: FC<Props> = ({ children }) => {
  const authQuery = useMe();
  const [mode, setMode] = useState<AuthMode>('login');

  const login = useCallback(() => {
    setMode('login');
  }, []);

  const recover = useCallback(() => {
    setMode('recover');
  }, []);

  if (authQuery.isLoading && !authQuery.isFetched) return <AppSplash />;

  return (
    <AuthContext.Provider value={{ login, recover }}>
      {authQuery.data ? children : modes[mode]}
    </AuthContext.Provider>
  );
};

export default AuthGuard;
