import { FC } from 'react';

import Box from '@mui/material/Box';

const Bullet: FC = () => (
  <Box component="span" sx={{ display: 'inline-block', mx: '2px' }}>
    •
  </Box>
);

export default Bullet;
