import { useCallback } from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import DialogButton from 'components/DialogButton';
import Stack from '@mui/material/Stack';
import FormTextField from 'components/FormTextField';

import { useUpdateMe } from 'features/Auth';
import { UserDetailsView } from 'schema';

type FormFields = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

type Props = {
  user: UserDetailsView;
};

const schema = object({
  firstName: string().required('Prašome įvesti vardą'),
  lastName: string().required('Prašome įvesti pavardę'),
  email: string()
    .required('Prašome įvesti el. pašto adresą')
    .email('Neteisingas el. pašto formatas'),
  phone: string().required('Prašome įvesti telefono numerį'),
});

const AccountSettingsDialogForm = ({ user }: Props) => {
  const { id, firstName, lastName, phone, ...rest } = user;
  const update = useUpdateMe();

  const handleSubmit = useCallback(
    (values: FormFields) => {
      update.mutate(values);
    },
    [update]
  );

  const initial = {
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    phone: phone ?? '',
    ...rest,
  };

  return (
    <Formik<FormFields>
      initialValues={initial}
      onSubmit={handleSubmit}
      validationSchema={schema}>
      <Form>
        <Box sx={{ minHeight: 'calc(100vh - 128px)', p: 2 }}>
          <Stack spacing={4}>
            <FormTextField name="firstName" label="Vardas" />
            <FormTextField name="lastName" label="Pavardė" />
            <FormTextField name="email" label="El. pašto adresas" />
            <FormTextField name="phone" label="Tel. numeris" />
          </Stack>
        </Box>

        <DialogButton
          type="submit"
          loading={update.isPending}
          endIcon={<SaveIcon />}>
          Saugoti
        </DialogButton>
      </Form>
    </Formik>
  );
};

export default AccountSettingsDialogForm;
