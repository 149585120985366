export function upperCaseFirst(string: string) {
  const first = string.charAt(0).toUpperCase();
  const rest = string.slice(1);

  return first + rest;
}

export function hash(string: string, count: number) {
  let hash = 0;

  if (string.length === 0) return hash;

  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return hash % count;
}

export function truncate(string: string, maxLength = 100) {
  if (string.length <= maxLength) {
    return string;
  }
  return string.slice(0, maxLength) + '...';
}
