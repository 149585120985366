import { FC } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

type Props = BoxProps;

const FullScreen: FC<Props> = ({ sx = [], ...rest }) => {
  return (
    <Box
      sx={[
        {
          bgcolor: '#f9fafb',
          p: 2,
          minHeight: 'calc(100vh - 56px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    />
  );
};

export default FullScreen;
