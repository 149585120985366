import { useCallback, MouseEvent } from 'react';

import DoneIcon from '@mui/icons-material/Done';
import EuroIcon from '@mui/icons-material/Euro';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import { ParentInvoiceListView } from 'schema';
import { useInvoiceStartPayment } from 'features/Invoice';

type Props = {
  invoice: ParentInvoiceListView;
};

const InvoiceRowPaymentButton = ({ invoice }: Props) => {
  const { id, status } = invoice;
  const startPayment = useInvoiceStartPayment(id);

  const handlePay = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      startPayment.mutate();
    },
    [startPayment]
  );

  if (status === 'paid') {
    return (
      <Button
        disabled
        variant="contained"
        size="small"
        startIcon={<DoneIcon />}>
        Apmokėta
      </Button>
    );
  }

  return (
    <LoadingButton
      variant="contained"
      size="small"
      startIcon={<EuroIcon />}
      loading={startPayment.isPending || status === 'payment_pending'}
      onClick={handlePay}>
      Mokėti
    </LoadingButton>
  );
};

export default InvoiceRowPaymentButton;
