import { useCallback } from 'react';

import LogoutIcon from '@mui/icons-material/Logout';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import CircularProgress from '@mui/material/CircularProgress';

import { useLogout } from 'features/Auth';

const LogoutMenuItem = () => {
  const logout = useLogout();

  const handleLogout = useCallback(() => {
    logout.mutateAsync().then(() => {
      window.location.reload();
    });
  }, [logout]);

  return (
    <ListItemButton disabled={logout.isPending} onClick={handleLogout}>
      <ListItemIcon>
        {logout.isPending ? (
          <CircularProgress size="1.2em" color="inherit" />
        ) : (
          <LogoutIcon />
        )}
      </ListItemIcon>

      <ListItemText>Atsijungti</ListItemText>
    </ListItemButton>
  );
};

export default LogoutMenuItem;
